    <template>
  <div class="home-icon-cont">
    <svg
      @click="$router.push('/')"
      class="home-icon pointer"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <path
          d="M503.401,228.884l-43.253-39.411V58.79c0-8.315-6.741-15.057-15.057-15.057H340.976c-8.315,0-15.057,6.741-15.057,15.057
			v8.374l-52.236-47.597c-10.083-9.189-25.288-9.188-35.367-0.001L8.598,228.885c-8.076,7.36-10.745,18.7-6.799,28.889
			c3.947,10.189,13.557,16.772,24.484,16.772h36.689v209.721c0,8.315,6.741,15.057,15.057,15.057h125.913
			c8.315,0,15.057-6.741,15.057-15.057V356.931H293v127.337c0,8.315,6.741,15.057,15.057,15.057h125.908
			c8.315,0,15.057-6.741,15.056-15.057V274.547h36.697c10.926,0,20.537-6.584,24.484-16.772
			C514.147,247.585,511.479,236.245,503.401,228.884z M433.965,244.433c-8.315,0-15.057,6.741-15.057,15.057v209.721h-95.793
			V341.874c0-8.315-6.742-15.057-15.057-15.057H203.942c-8.315,0-15.057,6.741-15.057,15.057v127.337h-95.8V259.49
			c0-8.315-6.741-15.057-15.057-15.057H36.245l219.756-200.24l74.836,68.191c4.408,4.016,10.771,5.051,16.224,2.644
			c5.454-2.41,8.973-7.812,8.973-13.774V73.847h74.002v122.276c0,4.237,1.784,8.276,4.916,11.13l40.803,37.18H433.965z"
        />
      </g>
    </svg>
  </div>
</template>

<script type="text/babel">
export default {
  name: "tabselector",

  components: {},
  computed: {},
  watch: {},
  data() {
    return {};
  },

  methods: {},

  created() {},

  mounted() {},

  beforeDestroyed() {},
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/styles/colors.scss";
.home-icon-cont {
  position: fixed;
  left: 10px;
  top: 10px;
  z-index:10000;
  .home-icon {
    width: 30px;
    * {
      fill: white;
    }
  }
}
@media (max-width: 768px) {
  .home-icon-cont {
    .home-icon {
      width: 25px;
    }
  }
}
</style>
