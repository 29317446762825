<template>
  <b-container style="max-width: 600px" class="stripe-bg">
    <bg-slide></bg-slide>
    <home-btn/>

    <div class="reg-cont mb-5">

      <div class="text-center mb-3 mb-lg-4">
        <b-img
          :src="require('@/assets/logo.svg')"
          style="width: 40%"
          class="pt-0 pt-lg-4 reg-logo"
        />
      </div>
      <h2 class="text-center white--text uppercase">
        <b>{{ $t('registration') }}</b>
      </h2>
      <h5 class="text-center mb-4 light--text">
        <b>{{ loginPersonal ? $t('personal_user') : $t('gyms') }}</b>
      </h5>
      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(submitRegistration)" class="mt-4">
          <b-form-group v-for="(item, i) in credentials_[type].text" :key="i">
            <validation-provider
              :name="$t(item.name)"
              :rules="item.rules"
              :ref="item.name"
              :vid="item.name === 'password' ? item.name : ''"
              mode="passive"
              v-slot="{ errors }"
            >
              <b-form-input
                :name="item.name"
                :placeholder="$t(item.name)"
                v-model="item.value"
                :size="windowW > 768 ? 'lg' : 'md'"
                class="form-control"
                autofocus=""
                :type="item.type ? item.type : 'text'"
              >
              </b-form-input>
              <small v-show="errors.length > 0" class="text-danger is-danger">
                <i class="fa fa-warning"></i> {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            v-for="item in credentials_[type].check"
            :key="item.name"
            :name="$t(item.name)"
            :rules="item.rules"
            v-slot="{ errors }"
          >
            <b-form-checkbox
              style="padding-top: 2px"
              :name="item.name"
              v-model="item.value"
            >
              <span
                class="light--text"
                v-html="$t(item.label ? item.label : item.name)"
              ></span>
            </b-form-checkbox>
            <small v-show="errors.length > 0" class="text-danger is-danger">
              <i class="fa fa-warning"></i> {{ errors[0] }}
            </small>
          </validation-provider>
          <div class="mt-3 text-center">
            <b-button
              type="submit"
              variant="primary"
              pill
              :size="windowW > 768 ? 'lg' : 'md'"
              :disabled="submitRegistrationSent"
            >
              {{ $t('save_account') }}
            </b-button>
          </div>

          <div class="mt-4">
            <router-link to="/login"> « {{ $t('back') }}</router-link>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-container>
</template>

<script type="text/babel">
import Vue from 'vue';
import { apiClient } from '@/util/resources';
import bgSlide from '@/components/bgSlide';
import homeBtn from '@/components/homeBtn';

export default Vue.extend({
  components: { bgSlide, homeBtn },
  data() {
    return {
      valuep: '',
      type: '',
      credentials_: {
        user: {
          text: [
            {
              name: 'email_address',
              rules: 'required|email',
              value: '',
              sendname: 'email',
            },
            {
              name: 'password',
              rules: 'required|min:4',
              value: '',
              type: 'password',
            },
            {
              name: 'confirm_password',
              rules: 'confirmed:password',
              value: '',
              type: 'password',
              sendname: 'passwordConfirm',
            },
          ],
          check: [
            {
              name: 'at',
              rules: { required: { allowFalse: false } },
              value: false,
              sendname: 'asz',
              label: 'at_ok',
            },

            {
              name: 'aszf',
              rules: { required: { allowFalse: false } },
              value: false,
              label: 'aszf_ok',
            },
            {
              name: 'overEighteen',
              rules: { required: { allowFalse: false } },
              value: false,
              sendname: 'age'
            },
            {
              name: 'newsletter',
              rules: '',
              value: true,
            },
          ],
        },
        gym: {
          text: [
            { name: 'firstname', rules: 'required', value: '' },
            { name: 'lastname', rules: 'required', value: '' },
            {
              name: 'email_address',
              rules: 'required|email',
              value: '',
              sendname: 'email',
            },
            {
              name: 'password',
              rules: 'required|min:4',
              value: '',
              type: 'password',
            },
            {
              name: 'confirm_password',
              rules: 'confirmed:password',
              value: '',
              type: 'password',
              sendname: 'passwordConfirm',
            },
            {
              name: 'phone_number',
              //rules: "required|numeric",
              value: '',
              sendname: 'phoneNumber',
              rules: { required: true, phone_number: /^\+?\d+([\- \/]*\d){10,}$/ },
            },
            {
              name: 'service_name',
              rules: 'required|min:3',
              value: '',
              sendname: 'username',
            },
            {
              name: 'zip',
              rules: 'required|min:4',
              value: '',
              sendname: 'zip',
            },
            { name: 'city', rules: 'required|min:3', value: '' },
            { name: 'address', rules: 'required|min:5', value: '' },
            {
              name: 'contact_name',
              rules: 'required|min:8',
              value: '',
              sendname: 'contactName',
            },
            {
              name: 'contact_phone_number',
              //rules: "required|numeric",
              rules: { required: true, phone_number: /^\+?\d+([\- \/]*\d){10,}$/ },
              value: '',
              sendname: 'contactPhoneNumber',
            },
            {
              name: 'contact_email',
              rules: 'required|email',
              value: '',
              sendname: 'contactEmail',
            },
          ],

          check: [
            {
              name: 'at',
              rules: { required: { allowFalse: false } },
              value: false,
              sendname: 'asz',
              label: 'at_ok',
            },
            {
              name: 'aszf',
              rules: { required: { allowFalse: false } },
              value: false,
              label: 'aszf_ok',
            },
            {
              name: 'overEighteen',
              rules: { required: { allowFalse: false } },
              value: false,
            },
            {
              name: 'newsletter',
              rules: '',
              value: true,
            },
          ],
        }
      },
      windowW: 0,
      submitRegistrationSent: false,
    };
  },
  name: 'reg',
  methods: {
    getSizes() {
      this.windowW = window.innerWidth;
    },
    submitRegistration() {
      this.submitRegistrationSent = true;
      let formData = {};
      for (let c in this.credentials_[this.type]) {
        this.credentials_[this.type][c].forEach((f) => {

          formData[f.sendname ? f.sendname : f.name] =
            f.value === null ? '' : f.value;
        });

      }
      let this_ = this;
      this.$store.commit('loader/show');
      apiClient
        .post(`api/users/${this_.type}`, formData)
        .then((response, error) => {
          this.$root.$bvToast.toast(this_.$t('reg_activate'), {
            title: this_.$t('success'),
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          });
           this.$store.dispatch('auth/onLogin', response.data).then(() => {
            this.$router.push({ name: 'channels' });
            this.$store.commit('loader/hide');
          });

        })
        .catch((error) => {
          this.$root.$bvToast.toast(error.message, {
            title: this.$i18n.t('_error'),
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            toaster: 'b-toaster-top-center',
          });
          this.submitRegistrationSent = false;
          this.$store.commit('loader/hide');
        });
    },
  },
  computed: {
    loginPersonal() {
      return this.$store.state.auth.loginPersonal;
    },
  },
  mounted() {
    this.getSizes();

    window.addEventListener('resize', this.getSizes);
  },
  created() {
    this.type = this.loginPersonal ? 'user' : 'gym';
  },
});
</script>
<style lang="scss">
@import "@/styles/colors.scss";

@keyframes scale0 {
  from {
    transform: scale(.2) translateY(-50%);
  }
  to {
    transform: scale(1) translateY(-50%);
  }
}

.reg-cont {
  background: #313131e3;

  border: 15px solid #505050;
  border-radius: 5px;
  padding: 30px 25px;
  margin-top: 50px;
  position: relative;
  top: calc(50% - 50px);
  transform: scale(1) translateY(-50%);
  animation-name: scale0;
  animation-duration: .5s;

  a {
    color: white;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $btnprimary;
    background-color: $btnprimary;
  }

}

@media (max-width: 768px) {
  .reg-cont {
    h2 {
      font-size: 5vw;
    }

    h5 {
      font-size: 4vw;
    }

    .reg-logo {
      width: 50% !important;
    }
  }
}


</style>
