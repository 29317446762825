<template>

    <b-carousel
      style="text-shadow: 0px 0px 2px #000" class="bg-carousel"
      fade
      :interval="5000"
      img-width="100%"
      img-height="100vh"
    >
      <b-carousel-slide
        v-for="(image, i) in imgs"
        :key="i"
        caption=""
      >
        <template #img>
          <img
            class="d-block img-fluid w-100 cover" style="height:100vh"

            :src="require(`@/assets/bg/${image}.jpg`)"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
    
 

</template>

<script type="text/babel">
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      imgs:['gym', '1']
    };
  },

  methods: {
    
  },

});
</script>
<style lang="scss">
@import "@/styles/colors.scss";
.bg-carousel {
  text-shadow: rgb(0 0 0) 0px 0px 2px;
  
  position: fixed;
width:100vw;
    height: 100vh;
  z-index: -1;
  left: 0;
  top: 0;

/*
filter: brightness(0.9);

border-radius: 50%;
    overflow: hidden;
    width:100vw;
    height: 100vw;
    max-width: 2000px;
    max-height: 2000px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border:1vw solid $btnprimary;
    */
  .img-fluid {
    object-fit: cover;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: #8a95a2;
    z-index: 2;
    mix-blend-mode: screen;
    background: linear-gradient(
        45deg, #145469, #929e71);
    background: linear-gradient(
        45deg, #145469, #929e71);
        background: linear-gradient(95deg, #1b1a1a, #000000);
        mix-blend-mode: color;
  }
}


</style>
